<template>
    <MapView v-if="!isMobile()"></MapView>
</template>

<script>
import MapView from "@/views/MapView";
import { useMobileDetection } from "vue3-mobile-detection";

export default {
    name: 'App',
    components: {
        MapView
    },
    setup() {
        const { isMobile } = useMobileDetection();
        if (isMobile()) {
            window.location.href = 'https://zalentour.fr/';
        }
        return { isMobile };
    }
}
</script>
