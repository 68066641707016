import { createApp } from 'vue'
import VueLazyLoad from 'vue3-lazyload'
import App from './App.vue'

import 'animate.css'
import 'bootstrap'
import 'bootstrap/scss/bootstrap.scss'

import './assets/css/font.css'
import './assets/css/base.css'

import '@fortawesome/fontawesome-free/css/all.min.css'

Array.prototype.clear = function() {
    this.splice(0, this.length);
};

createApp(App).use(VueLazyLoad).mount('#app');
