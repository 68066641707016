<template>
    <div class="map-container" :style="{width: '100%', height: mapHeight + 'px'}">
        <div id="map" data-nosnippet></div>
        <div id="map-sidebar">
            <SidebarHeader v-show="!selected_poi" :loading="loading"
                           :user_position="user_position" :categories="categories"
                           :data_length="data.length" @selectCategory="selectCategory"
                           @updateAllCategory="updateAllCategory">
            </SidebarHeader>
            <PoiList v-show="!selected_poi && !loading" :data="data" :categories="categories" @selectPoi="selectPoi"></PoiList>
            <PoiDetail v-if="selected_poi" :poi="selected_poi" :gmap_link="gmap_link" @clearSelection="clearSelection" @navigateToSelection="navigateToSelection"></PoiDetail>
        </div>
    </div>
</template>

<script>
import {onMounted, onUnmounted, ref, reactive, watch} from 'vue';
import { MarkerClusterer } from "@googlemaps/markerclusterer";
import {Loader} from "google-maps";
import axios from "axios";

import settings from "@/settings"
import SidebarHeader from "@/components/SidebarHeader";
import PoiList from "@/components/PoiList";
import PoiDetail from "@/components/PoiDetail"
import user_position_icon from "@/assets/images/markers/user_position.png";


export default {
    name: 'MapView',
    components: {
        PoiList,
        PoiDetail,
        SidebarHeader
    },
    setup() {
        let map = null;
        let cluster = null;
        let markers = [];
        const loading = ref(false);
        const data = reactive([]);
        const categories = ref({});
        const mapHeight = ref(window.innerHeight);
        let initial_position = null;
        let user_position = ref(null);
        let user_position_marker = null;
        let user_circle = null;
        let directionsDisplay = null;
        let directionsService = null;
        let gmap_link = ref(null);
        let selected_marker = null;
        let infowindow = null;
        let radius = 10000;
        const selected_poi = ref(null);

        function clearSelection() {
            if (selected_marker) {
                selected_marker.setAnimation(null);
            }
            removeNavigation();
            if (selected_poi.value) {
                let elem = document.getElementById("poi" + selected_poi.value.id);
                selected_poi.value = null;
                selected_marker = null;
                if (elem) {
                    setTimeout(function () {
                        elem.scrollIntoView({ behavior: 'auto', block: 'center' });
                    }, 200);
                }
            }
        }

        function setGMapLink() {
            let origin = user_position.value.lat + ',' + user_position.value.lng;
            let destination = selected_poi.value.latitude + ',' + selected_poi.value.longitude;
            gmap_link.value = 'https://www.google.com/maps/dir/?api=1&origin=' + origin + '&destination=' + destination;
        }

        function selectPoi(index) {
            if (selected_marker) {
                selected_marker.setAnimation(null)
            }
            if (index in data && index in markers) {
                const sidebar = document.getElementById("map-sidebar");
                if (sidebar) {
                    sidebar.scrollTo(0, 0);
                }
                removeNavigation();
                selected_poi.value = data[index];
                selected_marker = markers[index];
                map.setZoom(17);
                map.setCenter({
                    lat: data[index].latitude,
                    lng: data[index].longitude
                });
                setGMapLink();
                setTimeout(function () {
                    selected_marker.setAnimation(window.google.maps.Animation.BOUNCE);
                }, 500);
            }
        }

        function navigateToSelection(travelMode) {
            if (!travelMode) {
                travelMode = window.google.maps.TravelMode.DRIVING;
            }
            if (!user_position.value || !selected_poi.value) {
                return;
            }
            let start = user_position.value.lat + ', ' + user_position.value.lng;
            let end = selected_poi.value.latitude + ', ' + selected_poi.value.longitude;
            let request = {
                origin: start,
                destination: end,
                travelMode: travelMode
            };
            directionsDisplay.setMap(null);
            directionsService.route(request, function (response, status) {
                if (status === window.google.maps.DirectionsStatus.OK) {
                    directionsDisplay.setDirections(response);
                    directionsDisplay.setMap(map);
                    directionsDisplay.setOptions({suppressMarkers: true, polylineOptions: { strokeColor: '#ff0000', strokeOpacity: .5 } });
                    setTimeout(function () {
                        let navigation_container = document.getElementById('poi-detail-navigation');
                        directionsDisplay.setPanel(navigation_container);
                    }, 100);
                }
                else {
                    console.log(response);
                }
            });
        }

        function removeNavigation() {
            directionsDisplay.setMap(null);
            directionsDisplay.setPanel(null);
            let desc_tab = document.querySelector('#desc-tab');
            if (desc_tab) {
                desc_tab.click();
            }
        }

        function updateAllCategory(value) {
            for(let id in categories.value) {
                categories.value[id].enabled = value;
            }
            updateMarkers();
        }

        function selectCategory(category) {
            category.enabled = !category.enabled;
            updateMarkers();
        }

        function updateMarkers() {
            cluster.clearMarkers();
            let new_selection = [];
            for (let i = 0; i < data.length; i++) {
                if (categories.value[data[i].category.id].enabled) {
                    new_selection.push(markers[i]);
                }
            }
            cluster.addMarkers(new_selection);
        }

        function resizeMap() {
            mapHeight.value = window.innerHeight;
        }

        function getData(url) {
            if (!user_position.value) {
                return;
            }
            loading.value = true;
            let params = {
                latitude: user_position.value.lat,
                longitude: user_position.value.lng,
                radius: radius
            };
            axios({method: 'get', url: url, params: params}).then(
                (response) => {
                    data.push(...response.data.results);
                    loadMarkers(data);
                    setTimeout(function () {
                        loading.value = false;
                    }, 500);
                },
                (error) => {
                    console.log(error);
                }
            );
        }

        function loadMarkers(new_markers) {
            for (let i = 0; i < new_markers.length; i++) {
                let icon_url = settings.MEDIA_URL + new_markers[i].poi_marker_icon;
                let marker = new window.google.maps.Marker({
                    position: {lat:  new_markers[i].latitude, lng:  new_markers[i].longitude},
                    zIndex: 99999998,
                    optimized: true,
                    icon: {
                        url: icon_url,
                        scaledSize: new window.google.maps.Size(60, 60),
                    },
                    map: map
                });
                marker.addListener('mouseover', function () {
                    infowindow.setContent(new_markers[i].title);
                    infowindow.open(map, marker);
                    marker.setIcon({
                        url: icon_url,
                        scaledSize: new window.google.maps.Size(80, 80),
                    });
                });
                marker.addListener('mouseout', function () {
                    infowindow.close();
                    marker.setIcon({
                        url: icon_url,
                        scaledSize: new window.google.maps.Size(60, 60),
                    });
                });
                marker.addListener('click', function () {
                    if (selected_marker) {
                        selected_marker.setAnimation(null)
                    }
                    removeNavigation();
                    selected_poi.value = data[i];
                    selected_marker = markers[i];
                    setGMapLink();
                    selected_marker.setAnimation(window.google.maps.Animation.BOUNCE);
                });
                if (categories.value[new_markers[i].category.id] === undefined) {
                    categories.value[new_markers[i].category.id] = new_markers[i].category;
                    categories.value[new_markers[i].category.id].enabled = true;
                }
                markers.push(marker);
            }
            cluster.addMarkers(markers);
        }

        function createUserPositionMarker() {
            user_position_marker = new window.google.maps.Marker({
                position: user_position.value,
                optimized: false,
                draggable: true,
                zIndex: 99999999,
                icon: {
                    url: user_position_icon,
                    scaledSize: new window.google.maps.Size(60, 60),
                },
                animation: window.google.maps.Animation.BOUNCE,
                map: map,
            });
            user_circle.setMap(map);
            user_circle.bindTo('center', user_position_marker, 'position');
            window.google.maps.event.addListener(user_position_marker, 'dragstart', function() {
                clear();
            });
            window.google.maps.event.addListener(user_position_marker, 'dragend', function() {
                user_position.value = {
                    lat: user_position_marker.getPosition().lat(),
                    lng: user_position_marker.getPosition().lng()
                };
            });
        }

        function initSearchBox() {
            const input = document.getElementById("map-search");
            if (input) {
                const searchBox = new window.google.maps.places.SearchBox(input);
                searchBox.addListener("places_changed", () => {
                    const places = searchBox.getPlaces();
                    if (!places.length) {
                        return;
                    }
                    user_position.value = {
                        lat:places[0].geometry.location.lat(),
                        lng:places[0].geometry.location.lng()
                    };
                    map.setCenter(user_position.value);
                    map.setZoom(15);
                    if (user_position_marker) {
                        user_position_marker.setPosition(user_position.value);
                        user_position_marker.setMap(map);
                        user_circle.setMap(map);
                    }
                    else {
                        createUserPositionMarker();
                    }
                });
                input.addEventListener("keyup", event => {
                    if (!event.target.value) {
                        user_position.value = initial_position ? initial_position : null;
                        if (user_position_marker && user_position.value) {
                            user_position_marker.setPosition(user_position.value);
                        }
                        else {
                            user_position_marker.setMap(null);
                            user_circle.setMap(null);
                        }
                        if (user_position.value) {
                            map.setCenter(user_position.value);
                        }
                    }
                });
            }
        }

        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop)
        });

        function initMap() {
            let latitude = params.latitude ? parseFloat(params.latitude) : null;
            let longitude = params.longitude ? parseFloat(params.longitude) : null;
            let zoom = params.zoom ? parseInt(params.zoom) : null;
            radius = params.radius ? parseInt(params.radius) : radius;
            const options = {
                center: {lat: latitude && longitude ? latitude : 48.858395,
                    lng: latitude && longitude ? longitude : 2.372613},
                zoomControlOptions: { position: window.google.maps.ControlPosition.BOTTOM_LEFT },
                clickableIcons: false,
                fullscreenControl: false,
                streetViewControl: false,
                styles: settings.MAP_STYLE,
                zoom: zoom ? zoom : 8
            }
            map = new window.google.maps.Map(document.getElementById('map'), options);
            cluster = new MarkerClusterer({ map: map, markers: [] });
            infowindow = new window.google.maps.InfoWindow();
            user_circle = new window.google.maps.Circle({
                map: map,
                radius: radius,
                fillColor: '#000000',
                fillOpacity: 0,
                strokeColor: '#035499',
                strokeWeight: 1
            });
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(function (position) {
                    user_position.value = {lat: latitude && longitude ? latitude : position.coords.latitude,
                        lng: latitude && longitude ? longitude : position.coords.longitude};
                    initial_position = user_position.value;
                    map.setCenter(user_position.value);
                    if (!zoom) {
                        map.setZoom(12);
                    }
                    createUserPositionMarker();
                }, function(error) {
                    console.log(error);
                });
            }
            else {
                console.log('geolocation not available');
            }
            initSearchBox();
        }

        function clear() {
            data.clear();
            categories.value = {};
            clearMarkers();
            clearSelection();
        }

        function clearMarkers() {
            for (let i = 0; i < markers.length; i++) {
                markers[i].setMap(null);
            }
            markers.clear();
            cluster.clearMarkers();
        }

        onMounted(function() {
            if (!window.google) {
                const loader = new Loader(settings.GG_API_KEY, {
                    libraries: ['places'],
                });
                loader.load().then(function (google) {
                    window.google = google;
                    directionsDisplay = new google.maps.DirectionsRenderer();
                    directionsService = new google.maps.DirectionsService();
                    initMap();
                });
            }
            else {
                initMap();
            }
            window.addEventListener("resize", resizeMap);
        });

        onUnmounted(function () {
            window.removeEventListener("resize", resizeMap);
        });

        watch(() => user_position.value, function() {
            clear();
            getData(settings.POI_URL);
        });

        return {
            data,
            categories,
            mapHeight,
            user_position,
            loading,
            gmap_link,
            selected_poi,
            selectPoi,
            selectCategory,
            updateAllCategory,
            navigateToSelection,
            clearSelection,
        }
    }
}
</script>

<style scoped>
#map {
    width: calc(100% - 500px);
    height: 100%;
}
#map-sidebar {
    width: 500px;
    height: 100%;
    position: absolute;
    z-index: 1;
    overflow-y: auto;
    overflow-x: hidden;
    background-color: #fafafa;
    background-clip: border-box;
    border: 0 solid #f7f7f7;
    border-radius: .25rem;
    box-shadow: 0 1px 3px 0 rgb(0 0 0 / 20%);
    right: 0;
    top: 0;
}
.map-container {
    position: relative;
}
</style>
