<template>
    <div class="poi-detail">
        <div class="poi-detail-image" @click="showViewer()">
            <img :src="image_base_url + poi.poi_image" class="img-fluid">
        </div>
        <div class="poi-detail-back" @click="clearSelection()">
            <i class="fa-solid fa-bars"></i>
            <span class="ms-2">Liste</span>
        </div>
        <div class="poi-detail-title">{{ poi.title }}</div>
        <div class="row poi-detail-info gx-1">
            <div class="col-3-5">
                <div class="mb-2"><i class="fa-solid fa-list-check"></i></div>
                <p class="mb-0">{{ poi.category.title }}</p>
            </div>
            <div class="col-separator">
                <div class="tile-separator"></div>
            </div>
            <div class="col-6">
                <div class="mb-2"><i class="fa-solid fa-city"></i></div>
                <p class="mb-0">{{ poi.city }}</p>
            </div>
            <div class="col-separator">
                <div class="tile-separator"></div>
            </div>
            <div class="col-3-5">
                <div class="mb-2"><i class="fa-solid fa-location-crosshairs"></i></div>
                <p class="mb-0">{{ showDistance(poi.distance) }}</p>
            </div>
        </div>
        <div class="poi-detail-nav">
            <ul class="nav nav-tabs mt-3" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="desc-tab" data-bs-toggle="tab" data-bs-target="#poi-detail-description"
                            type="button" role="tab" aria-controls="poi-detail-description" aria-selected="true">
                        Description
                    </button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="nav-tab" data-bs-toggle="tab" data-bs-target="#poi-detail-navigation" type="button"
                            role="tab" aria-controls="poi-detail-navigation" aria-selected="false" @click="navigateToSelection()">
                        Navigation
                    </button>
                </li>
            </ul>
            <div class="tab-content">
                <div class="tab-pane fade show active poi-tab-description" id="poi-detail-description" role="tabpanel"
                     aria-labelledby="desc-tab">
                    <p class="mb-0 text-justify" v-if="poi.description">{{ poi.description }}</p>
                    <p class="mt-3 mb-0" v-if="poi.construction_period">
                        <strong>Période de construction</strong> : {{ poi.construction_period }}
                    </p>
                    <p class="mt-3 mb-0" v-if="poi.visit">
                        <strong>Visite</strong> : {{ poi.visit }}
                    </p>
                    <p class="mt-3 mb-0" v-if="poi.schedule">
                        <strong>Horaire</strong> : {{ poi.schedule }}
                    </p>
                    <div v-if="poi.premium">
                        <p class="mt-3 mb-0" v-if="poi.phone">
                            <strong>Téléphone</strong> : {{ poi.phone }}
                        </p>
                        <p class="mt-3 mb-0" v-if="poi.email">
                            <strong>Email</strong> : {{ poi.email }}
                        </p>
                        <p class="mt-3 mb-0" v-if="poi.web">
                            <strong>Website</strong> : <a :href="poi.web">{{ poi.web }}</a>
                        </p>
                    </div>
                </div>
                <div class="tab-pane fade" id="poi-detail-navigation" role="tabpanel" aria-labelledby="nav-tab">
                    <a class="btn-link" v-if="gmap_link" target="_blank" :href="gmap_link"><i class="fa-solid fa-location-arrow me-1"></i>Ouvrir dans google maps</a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import settings from "@/settings";
import { v3ImgPreviewFn } from 'v3-img-preview'
import {showDistance} from "@/mixins/distance";

export default {
    name: 'PoiDetail',
    props: ['poi', 'gmap_link'],
    emits: ['clearSelection', 'navigateTo'],
    setup(props, context) {
        const image_base_url = settings.MEDIA_URL;

        const showViewer = () => {
            v3ImgPreviewFn(image_base_url + props.poi.poi_image);
        }

        function clearSelection() {
            context.emit('clearSelection');
        }

        function navigateToSelection() {
            context.emit('navigateToSelection');
        }

        return {
            image_base_url,
            showViewer,
            navigateToSelection,
            clearSelection,
            showDistance
        }
    }
}
</script>

<style scoped>
.poi-detail-image {
    width: 100%;
    height: 350px;
    overflow: hidden;
    cursor: zoom-in;
}
.poi-detail-image img {
    width: 100%;
    height: 350px;
    object-fit: cover;
    -webkit-transition: all 1s ease; /* Safari et Chrome */
    -moz-transition: all 1s ease; /* Firefox */
    -ms-transition: all 1s ease; /* Internet Explorer 9 */
    -o-transition: all 1s ease; /* Opera */
    transition: all 1s ease;
}
.poi-detail-image img:hover {
    /* L'image est grossie de 25% */
    -webkit-transform: scale(1.25); /* Safari et Chrome */
    -moz-transform: scale(1.25); /* Firefox */
    -ms-transform: scale(1.25); /* Internet Explorer 9 */
    -o-transform: scale(1.25); /* Opera */
    transform: scale(1.25);
}
.poi-detail-info {
    text-align: center;
    padding-top: 25px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 10px;
    color: #495057;
}
.poi-detail-info i {
    font-size: 1.5rem;
}
.poi-detail h6 {
    font-weight: 600;
}
.poi-detail-title {
    position: absolute;
    transform: translateY(-100%);
    left: 0;
    color: white;
    background-color: rgba(0, 0, 0, .4);
    padding: 10px;
    width: 100%;
    font-size: 1.1rem;
}
.poi-detail-back {
    position: absolute;
    left: 0;
    top: 0;
    color: white;
    background-color: rgba(0, 0, 0, .4);
    padding: 10px;
}
.poi-detail-back:hover {
    font-weight: bold;
    text-decoration: underline;
    cursor: pointer;
}
.tab-content {
    padding: 10px;
    line-height: 25px;
    background-color: white;
    border-left: 1px solid #dee2e6;
    border-right: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
}
.nav-link {
    color: #495057;
}
.nav-link.active, .nav-link:hover {
    color: #00a3c9 !important;
}
.poi-detail-nav {
    padding: 10px;
}
.btn-link {
    user-select: none;
    color: #00a3c9;
}
.btn-link i {
    color: #00a3c9;
}
.btn-link:hover i {
    color: #035499;
}
.btn-link:hover {
    cursor: pointer;
    color: #035499;
    text-decoration: underline;
}

</style>
