<template>
    <div class="poi-list" v-show="data">
        <div v-for="(poi, index) in data" :key="poi.id" :id="'poi' + poi.id">
            <div class="poi-thumb" @click="selectPoi(index)" v-show="categories[poi.category.id] && categories[poi.category.id].enabled">
                <div class="row">
                    <div class="col-4">
                        <div class="poi-list-image">
                            <img v-lazy="{ src: image_base_url + poi.poi_image, loading: loader_url}" class="img-fluid">
                        </div>
                    </div>
                    <div class="col-8 poi-thumb-detail">
                        <h6 class="mb-4 text-truncate" :title="poi.title">{{ poi.title }}</h6>
                        <p class="mb-2"><i class="fa-solid fa-list-check me-2"></i>{{ poi.category.title }}</p>
                        <p class="mb-2"><i class="fa-solid fa-location-crosshairs me-2"></i>{{ showDistance(poi.distance) }}</p>
                        <p class="mb-0"><i class="fa-solid fa-city me-2"></i>{{ poi.city }}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import settings from "@/settings";
import {showDistance} from "@/mixins/distance";

export default {
    name: 'PoiList',
    props: ['data', 'categories'],
    emits: ['selectPoi'],
    setup(props, context) {
        const loader_url = require('@/assets/images/loader.svg')
        const image_base_url = settings.MEDIA_URL;

        function selectPoi(index) {
            context.emit('selectPoi', index);
        }

        return {
            selectPoi,
            showDistance,
            loader_url,
            image_base_url
        }
    }
}
</script>

<style scoped>
.poi-list {
    padding: 0 10px 0 10px;
}
.poi-image {
    width: 100%;
    height: 140px;
    object-fit: cover;
}
.poi-thumb {
    overflow: hidden;
    padding: 10px 0 10px 0;
    border-bottom: 1px solid #DCDDDF;
    cursor: pointer;
}
.poi-thumb .fa-solid {
    height: 20px;
    width: 20px;
    text-align: center;
}
.poi-thumb-detail {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.poi-thumb:hover {
    background-color: #00a3c9;
    color: white;
    margin-left: -10px;
    padding-left: 10px;
    margin-right: -10px;
    padding-right: 10px;
}
.poi-list-image {
    width: 100%;
    height: 140px;
    overflow: hidden;
}
.poi-list-image img {
    width: 100%;
    height: 140px;
    object-fit: cover;
    -webkit-transition: all 1s ease; /* Safari et Chrome */
    -moz-transition: all 1s ease; /* Firefox */
    -ms-transition: all 1s ease; /* Internet Explorer 9 */
    -o-transition: all 1s ease; /* Opera */
    transition: all 1s ease;
}
.poi-list-image img:hover {
    /* L'image est grossie de 25% */
    -webkit-transform: scale(1.25); /* Safari et Chrome */
    -moz-transform: scale(1.25); /* Firefox */
    -ms-transform: scale(1.25); /* Internet Explorer 9 */
    -o-transform: scale(1.25); /* Opera */
    transform: scale(1.25);
}
</style>