<template>
    <div class="sidebar-header">
        <input
            autocomplete="off"
            id="map-search"
            class="inputtext"
            type="text"
            placeholder="Entrez une ville ou une adresse" />
        <p class="mt-3 mb-0 text-justify" v-if="!user_position">
            Pour trouver le point d'interet le plus proche de votre position, veuillez entrer une adresse dans la barre de recherche ci-dessus.
        </p>
        <p class="mt-3 mb-0 text-justify" v-if="!data_length && user_position && !loading">
            Aucun résultat, utilisez la barre de recherche ou deplacez le marqueur pour changer de position
        </p>
        <div v-if="loading" class="mt-3">
            <p class="mb-1">Chargement des points d'intérêts</p>
            <div class="progress">
                <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" :style="{width: 100 + '%'}"
                     :aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
            </div>
        </div>
        <div v-if="!loading && data_length" class="accordion mt-2" id="poi-categories">
            <div class="accordion-item">
                <h2 class="accordion-header" id="poi-categories-header">
                    <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#poi-categories-body" aria-controls="collapseOne">
                        Categories
                    </button>
                </h2>
                <div id="poi-categories-body" class="accordion-collapse collapse" aria-labelledby="poi-categories-header" data-bs-parent="#poi-categories">
                    <div class="accordion-body">
                        <div class="row mb-2">
                            <div class="col-12 text-center">
                                <span class="btn-category me-4" @click="updateAllCategory(true)">
                                    <i class="fa-solid fa-square-check me-1"></i>Toute categories</span>
                                <span class="btn-category" @click="updateAllCategory(false)">
                                    <i class="fa-solid fa-square-xmark me-1"></i>Aucune categorie</span>
                            </div>
                        </div>
                        <div class="row">
                            <div v-for="c in categories" :key="c.id" :id="'categorie' + c.id" class="col-3">
                                <div class="category text-center pt-2" @click="selectCategory(c)" :title="c.title" :class="{'category-disabled': !c.enabled }">
                                    <img :src="settings.MEDIA_URL + c.icon" class="img-fluid mb-2">
                                    <p class="mb-0 text-truncate">{{ c.title }}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import settings from "@/settings";

export default {
    name: 'SidebarHeader',
    props: ['loading', 'user_position', 'categories', 'data_length'],
    emits: ['selectCategory', 'updateAllCategory'],
    setup(props, context) {

        function selectCategory(category) {
            context.emit('selectCategory', category);
        }

        function updateAllCategory(value) {
            context.emit('updateAllCategory', value);
        }

        return {
            settings,
            selectCategory,
            updateAllCategory
        }
    }
}
</script>

<style scoped>
.sidebar-header {
    top: 0;
    z-index: 9;
    position: sticky;
    background-color: white;
    padding: 10px;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}
#map-search {
    width: 100%;
    transition: width calc(1s / 2) ease;
}
.progress {
    height: 25px;
}
.category img {
    height: 50px;
}
.category:hover {
    cursor: pointer;
    color: #00a3c9;
}
.category-disabled {
    opacity: .5;
}
.btn-category {
    user-select: none;
}
.btn-category i {
    color: #00a3c9;
}
.btn-category:hover {
    cursor: pointer;
    color: #00a3c9;
    text-decoration: underline;
}
</style>